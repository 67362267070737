import React from 'react';
import {Col, Container, Row, Tab, Nav} from "react-bootstrap";
import HtmlParser from 'react-html-parser';

function Developmentprocess({data}) {
    if (data?.development_process_title) {
        return (
            <section className="developmemt_proces p-100">
                <Container className="h-100">
                    <Row className="h-100">
                        <Col xs={12} md={6} lg={6} xl={6} xxl={6} className="my-auto">
                            <div className="example" data-text={data?.development_process_tabs_body_txt}>
                                <h2>{HtmlParser(data?.development_process_title)}</h2>
                            </div>
                        </Col>
                        <Col xs={12} md={12} lg={12} xl={12} xxl={12} className="my-auto">
                            <Tab.Container defaultActiveKey="0">
                                <Row>
                                    <Col xs={12} md={5} lg={5} xl={5}>
                                        <Nav variant="pills" className="flex-column">
                                            {data?.development_process_tabs?.map((value, i) => (
                                                <Nav.Item key={i}>
                                                    <Nav.Link eventKey={i}>{value.title}</Nav.Link>
                                                </Nav.Item>
                                            ))}
                                        </Nav>
                                    </Col>
                                    <Col xs={12} md={7} lg={7} xl={7} className="my-auto">
                                        <Tab.Content>
                                            {data?.development_process_tabs?.map((value, i) => (
                                                <Tab.Pane eventKey={i} key={i}>
                                                    <h4>{value.title}</h4>
                                                    <div> {HtmlParser(value.description)}</div>
                                                </Tab.Pane>
                                            ))}
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    } else {
        return ('');

    }

}

export default Developmentprocess;